.expressionBox {
    display: flex;
    align-items: center;
    background-color: #eee;
    border-radius: 8px;
    padding: 0.5em;
    width: 60%;
    position: relative;
    height: 35px; /* Adjust this based on desired height */
    overflow: hidden;
  }
  
  .expressionInput {
    flex: 1;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 1.2em;
    height: 100%; /* Make sure the input takes full height of the parent */
    padding: 0; /* Remove default padding */
  }

  math-field {
    --background-color: transparent; /* Make background transparent */
    --smart-fence-color: transparent;
    width: 100%;  /* Ensure math-field takes full width */
    height: 100%;  /* Ensure math-field takes full height */
    display: block;  /* Makes it behave like a block element */
    font-size: 15pt;
    margin-right: 34px;
    overflow-x: auto; /* Enable horizontal scrolling if needed */
    overflow-y: hidden; /* Hide vertical scrollbar */
    white-space: nowrap; /* Prevent wrapping of text in math-field */
    text-overflow: ellipsis; /* Add ellipsis for truncated content */
  }