:root {
  --main-bg-color: rgb(255, 245, 230);
  --button-bg-color: rgb(241, 139, 2);
  --navbar-height: 70px;
  --chat-footer-height: 30px;
  --wide-screen-font-size: 1.2rem;
  --small-screen-font-size: 1rem;
}

body {
  font-family: 'Baloo Tamma 2', sans-serif;
  background-color: var(--main-bg-color);
  color: #333;
  margin: 0;
  overflow: hidden;
  line-height: 1;
}


input, button, select, textarea {
  font-family: 'Baloo Tamma 2', sans-serif;
  line-height: 1;
}


.landing, .login {
  font-family: 'Baloo Tamma 2', sans-serif;
  width: 100vw;
  align-items: center;
  height: calc(75vh + 40px);
  padding: calc(25vh - 80px) 0 0;
}

.landing, .login, .page {
  overflow-y: auto;
}

@media all and (orientation: landscape) {
  .landing h1, .login h1 {
    font-size: 5em;
  }
  .landing h3, .login h3 {
    font-size: 2.5em;
  }

  .login form {
    width: 25vw;
    padding: 20px 40px;
  }

  .login form button, .loginRow input {
    font-size: 24px;
  }
  .loginRow {
    margin-bottom: 20px;
  }
  .login form p {
    margin-top: 10px;
  }
  p.switchLoginRegister {
    font-size: 18px;
  }
}

@media all and (orientation: portrait) {
  .landing h1, .login h1 {
    font-size: 4em;
  }
  .landing h3, .login h3 {
    font-size: 2em;
  }
  .login form {
    width: 70vw;
    padding: 15px 30px;

  }
  .login form button, .loginRow input {
    font-size: 16px;
  }
  .loginRow {
    margin-bottom: 15px;
  }
  .login form p {
    margin-top: 7.5px;
  }
  p.switchLoginRegister {
    font-size: 14px;
  }
}

.landing h1, .login h1 {
  margin-bottom: 1vh;
}

.landing h3, .login h3 {
  margin-bottom: 4vh;
}


.landingLink {
  height: max(150px, 12.5vw);
  width: max(150px, 12.5vw);
  border-radius: 50%;
  text-decoration: none;
  font-weight: bold;
  font-size: max(1.1em, 1.5vw)
}

.landingLink#studentLink {
  margin-right: max(2.5vw, 15px);
}

.landingLink#teacherLink {
  margin-left: max(2.5vw, 15px);
}

.page {
  background-color: var(--main-bg-color);
  width: calc(100vw - 40px);
  height: calc(100vh - 90px);
  padding: 0 20px 10px;
}

/* .page, .page * {
  scrollbar-width: none;
} */

.sectionHeader {
  font-size: 1.2em;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.studentHomeContainer {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.welcomeBox {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 25%;
  height: calc(100% - 40px);
}

.welcomeBox h3 {
  margin-top: 0;
}

.welcomeBox hr {
  border: 0.5px solid #ddd;
}

.yourClasses {
  color: var(--button-bg-color);
  padding-top: 0.5em;
}


/* Upcoming Assignments Header */
.upcoming-assignments {
  color: var(--button-bg-color);
  border-bottom: none;
  font-size: 24px;
  padding-bottom: 0.5em;
}

/* Style for the due date text */
.due-date-text {
  color: black;
  font-size: 20px;
  padding-bottom: 10px;
  text-align: left;
}

/* Assignment Container */
.assignment-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch; /* Ensure child elements stretch to full height */
  background-color: white;
  border-radius: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  overflow: hidden; /* Ensure content stays inside the container */
  height: auto; /* Adjusts height based on content */
}

/* Horizontal Divider between assignments */
.assignment-divider {
  border: none;
  height: 1px;
  background-color: #ccc; /* Light grey line */
  margin: 15px 0;
}

/* Class Name Section */
.class-name {
  background-color: var(--button-bg-color); /* Orange background */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  flex: 0 0 25%; /* Fixed width (25%) of the container */
  padding: 10px; /* Adds padding inside the orange section */
  font-size: 20px; /* Default font size */
  overflow-wrap: break-word; /* Ensures long words break to the next line */
  word-wrap: break-word; /* Wrap long text */
  white-space: normal; /* Allow the text to wrap onto the next line */
}

/* Adjust font size for longer text */
.class-name p {
  font-size: clamp(12px, 1vw, 16px); /* Dynamically adjust font size between 12px and 16px */
  margin: 0;
}

/* Assignment Info Section */
.assignment-info {
  padding: 15px;
  border-radius: 10px 0 0 10px;
  flex: 1; /* Allow the assignment info to take up the rest of the space */
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  text-align: center; /* Center text within this section */
}

.assignment-info h3 {
  margin: 0 0 10px;
}

.assignment-info p {
  margin: 5px 0 0 0;
}


/* Start Button */
.start-button {
  background-color: var(--button-bg-color);
  color: white;
  padding: 11px 30px 9px;
  border-radius: 10px;
  border: none;
  font-weight: bold;
  margin: 25px; /* Add margin to create space around the button */
  height: auto; /* Prevent button from stretching vertically */
  align-self: center;
  font-size: 18px;
}

.classList {
  margin-top: 15px;
}

.assignmentsSection {
  flex-grow: 1;
}

.classTitle {
  flex: 2;
  text-align: left;
}
  

.classButton {
  background: none; /* Remove background color */
  color: black;
  padding: 10px;
  border: none;
  cursor: pointer;
  display: flex; /* Use flexbox for horizontal alignment */
  flex-direction: row; /* Align stars and class text in a row */
  align-items: center; /* Vertically align stars and text */
  width: 100%;
  text-align: left;
  font-size: 16px;
}

.classButton:hover {
  background-color: #f0f0f0;
}

.starSymbol {
  color: gold;
  font-size: 18px;
  margin-right: 10px; /* Add space between the star and the class text */
}

.classTextContainer {
  display: flex;
  flex-direction: column; /* Stack class name and assignment count vertically */
}

.className {
  font-weight: bold;
  color: black;
  font-size: 18px;
}

.assignmentCount {
  color: grey;
  font-size: 14px;
  margin-top: 4px;
}

/* Additional Styling for Tables */
table, th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}


nav#navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: calc(100vw - 40px);
  height: var(--navbar-height);
  margin: 5px 20px 5px;
  justify-content: space-between; /* Spread out the elements */
}

.logoButton {
  font-size: 1.5em;
  padding: 10px 2px;
  border-radius: 7px 0;
}

.orangeButton {
  background-color: var(--button-bg-color); /* Orange background for buttons */
  color: var(--main-bg-color); /* Set text color for buttons */
}

.logoButton, .navButton {
  font-weight: bold;
  text-decoration: none; /* Remove underline from links */
  border: none;
}

/* Navbar Button Styling */
.navButton {
  padding: 10px 20px;
  border-radius: 25px; /* Round the button for a modern look */
  font-family: 'Baloo Tamma 2', sans-serif; /* Clean, professional font */
  font-size: 1.1em;
}

.popoutButton {
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
  cursor: pointer;
}

.popoutButton:hover {
  background-color: #e67e22; /* Slightly darker orange on hover */
  transform: scale(1.05); /* Slight scaling for interactivity */
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2); /* Add depth on hover */
}


h1, .page p, h3, .login p, .chatPageTitle, .chatPageSubtitle {
  margin-block-end: 0;
  margin-block-start: 0;
}

.pageTitle {
  margin-block-start: 0;
  margin-bottom: 1em;
}

.login form {
  border-radius: 10px;
  align-items: center;
  border: 3px solid var(--button-bg-color);
  margin-bottom: 10px;
}

.login form button {
  border: none;
  padding: 8px;
  border-radius: 10px;
  font-weight: bold;
}

.loginRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.loginRow input {
  padding: 8px 10px;
  background-color: var(--main-bg-color);
  border: 2px solid #757575;
  border-radius: 5px;
  width: 100%;
}

input:focus {
  outline: none;
}

.assignmentRow {
  display: flex;
  flex-direction: row;
  margin-top: 5px
}

.assignmentDescriptor:hover {
  background-color: #ddd;
  cursor: pointer;

}

.classTitle {
  width: 20vw;
}

.assignmentName {
  width: 25vw;
}

.assignmentDueDate {
  width: 20vw;
}

.assignmentCompletion {
  width: 15vw;
}


.errorMessage {
  color: #900;
}

.selectClass {
  padding: 20px;
  border-radius: 20px;
  border: solid 1px black;
}

.selectClass:hover {
  background-color: #ddd;
}

.sectionHeader {
  margin-top: 0;
  margin-bottom: 0;
  border-bottom: 1px solid black;
  width: fit-content;
}

.teacherSection {
  margin-bottom: 1em;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.centerCenter {
  align-items: center;
  justify-content: center;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.newQuestion {
  align-items: flex-start;
  justify-content: flex-start;
  width: fit-content;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 15px;
}

.newQuestion.saved {
  background-color: #dfdfdf;
}

.newQuestion.unsaved {
  background-color: #f2f2f2;
}
.questionComponent, .objectName {
  align-items: center;
}
.questionComponent, .newQuestion > button {
  margin-bottom: 5px;
}

.questionComponent > input, .questionComponent > select, .paddedInput {
  margin-right: 8px;
  margin-left: 8px;
}

.actionButton {
  display: block;
  margin-bottom: 15px;
  border: none;
  padding: 5px 10px;
  border-radius: 10px;
}

.actionButton:hover {
  opacity: .9;
}

.actionButton:active {
  opacity: .7;
}

.deleteQuestionButton {
  margin-right: 0;
  margin-left: auto;
}

.assignmentProp {
  margin-left: 12px;
}

.assignmentPropRow {
  margin-bottom: 15px;
}

.graphManager input[type=number] {
  width: 75px;
}

.objectName {
  margin-left: 8px;
  border: 1px solid black;
  border-radius: 20px;
  padding: 0px 5px;
}

.objectName button {
  border: none;
  background: none;
}

.chatInputRow {
  align-items: center;
  justify-content: flex-start;
  margin: 2px 0px;
  width: var(--question-box-width);
  margin-left: var(--question-box-margin);
}

.chatInputRow select {
  padding: 5px;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 5px;
}

input.newAnswer {
  padding: 5px;
  border-radius: 5px;
}

/* Hide the MathLive virtual keyboard toggle */
math-field::part(virtual-keyboard-toggle) {
  display: none;
}

/* Hide the MathLive menu toggle */
math-field::part(menu-toggle) {
  display: none;
}

math-field {
  display: block;
  width: 40%;
}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}

.pointTableCell {
  min-width: 20px;
  padding: 2px;
}

.pointTableCell input {
  width: 30px;
}



.logo {
  height: 40px;
  width: auto;
  margin-right: 8px;
}

/* Imported landing page, TODO refactor */

#importedLanding {
  margin: 0;
  padding: 0;
  background-color: #fff5e6;
  font-size: 18px; /* Increased base font size for better readability */
  line-height: 1.6; /* Improved line height for readability */
  width: 100%;
}

#importedLanding .container {
  width: 80%;
  max-width: 1200px; /* Set a max-width to avoid overly wide lines of text */
  margin: auto;
}

#importedLanding nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#importedLanding nav ul {
  list-style: none;
  padding: 0;
}

#importedLanding nav ul li {
  display: inline;
  margin-left: 20px;
}

#importedLanding nav a {
  text-decoration: none;
  color: white;
  font-size: 1rem;
}

#importedLanding .hero {
  padding: 50px 0;
  background: #fff5e6;
  text-align: center;
}

#importedLanding .hero h1 {
  color: #f28b04;
  font-size: 3rem; /* Larger font size for the main title */
  Margin-bottom: 0;
}

#importedLanding .hero p {
  color: #333;
}

#importedLanding .cta-button {
  text-decoration: none;
  background-color: #f28b04;
  color: #ffffff;
  padding: 12px 25px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

#importedLanding .cta-button:hover {
  background-color: #cc8400;
}

#importedLanding .about, #importedLanding .benefits, #importedLanding .testimonials, #importedLanding .demo-form, #importedLanding footer {
  text-align: left; /* Align to the left instead of center */
}

#importedLanding Section {
  padding: 15px 0;
}

#importedLanding .about img, #importedLanding .testimonials video {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
}

#importedLanding footer {
  background: #333;
  color: white;
  padding: 20px 0;
  text-align: center; /* Centered text for footer */
}

#importedLanding footer a {
  color: #f28b04;
  text-decoration: none;
}

#importedLanding footer p {
  font-size: 0.8em;
}

#importedLanding #importedLanding .testimonial-quote {
  font-style: italic;
  margin: 20px 0;
}

/* Responsive Grid for Features Section */
#importedLanding .benefits ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 20px;
  padding: 0;
}

#importedLanding .benefits li {
  list-style-type: none;
  background: #ffffff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}


/* Quote Section Styles */
#importedLanding .quote-section {
  margin: 0px 30px;
  background-color: #f9f9f9; /* Light background for the quote section */
  padding: 20px; /* Padding inside the quote section */
  font-style: italic; /* Italic font for quotes */
  border-left: 4px solid #f28b04; /* Accent border on the left */
  position: relative; /* For positioning the quote icon */
}


#importedLanding .quote-section p {
  margin: 0 0 10px 0; /* Margin for paragraph within the quote section */
}

#importedLanding .quote-author {
  text-align: right; /* Align the author to the right */
  font-style: normal; /* Normal font style for author */
  font-weight: bold; /* Bold font weight for author */
  font-size: 0.9em; /* Slightly smaller font size for author */
  margin: 0; /* Remove margin for author */
}

#importedLanding p {
  margin: 0
}

/* Chat Styles */
#importedLanding .chat-container {
  max-width: 1000px;
  margin: 20px auto;
  border: 2px solid #f28b04; 
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Adds subtle shadow */
  border-radius: 8px; /* Optional: Rounds the corners of the chat container */

}


#importedLanding .chat-message {
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 20px;
  display: inline-block;
  max-width: 80%;
}

#importedLanding .user-message {
  background-color: #a2d2ff;
  text-align: left;
  margin-left: 20px;
}

#importedLanding .user-message-wrapper {
  text-align: right;
}

#importedLanding .assistant-message {
  background-color: #f9f9f9;
  text-align: left;
  margin-right: 20px;
  margin-left: auto;
}

#importedLanding .assistant-message-wrapper {
  Text-align: left;
}

/* Clearfix for .chat-message */
#importedLanding .chat-message::after {
  content: "";
  clear: both;
  display: table;
}

#importedLanding .chat-message p {
  margin: 0;
}


#importedLanding .site-header {
    display: flex;
    align-items: center; /* Align items vertically */
    padding: 10px;       /* Add some padding around the content */
}

#importedLanding .site-header a {
    display: inline-flex; /* Keeps the link element inline and allows flex properties */
    text-decoration: none; /* Removes underline from anchor tag if applicable */
}

#importedLanding .site-header img {
    height: auto;    /* Maintain aspect ratio */
    max-height: 50px; /* Maximum height of the logo; adjust as needed for 'small' size */
    max-width: 100%; /* Ensures the image is responsive */
}


/* Chat.module.css and related*/


.messageWrapper, .pastMessages {
  display: block;
  justify-content: flex-end;
}

.messageWrapper {
  height: calc(85vh - 110px);
  background-color: #eee;
  border-radius: 20px;
  padding: 20px;
  margin: 1em 0;
}

.pastMessages * {
  overflow-anchor: none;
}

#scroll-anchor {
  overflow-anchor: auto;
  height: 1px;
}



.newMessage, .newAnswer {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.newMessageBox > .flexRow {
  width: 100%;
}

.chatPageSubtitle, .chatPageTitle {
  color: var(--button-bg-color);
}



.chatPageTitle {
  margin-bottom: 5px;
}

.pageTitleContainer {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .pageTitle {
    font-size: 1.2rem;
  }

  .chatPageTitle {
    font-size: 20px;
  }

  .chatPageSubtitle {
    font-size: 15px;
  }
  .suggestionButton {
    padding: 6px 8px 4px;
  }
  .chatNavButton, .messageRow, .suggestionButton, .chatInputRow *, .newMessage, .newMessage::placeholder {
    font-size: var(--small-screen-font-size);
  }
  .hideOnQuery {
    display: none;
  }
}

@media (min-width: 769px) {
  .chatPageTitle {
    font-size: 30px; 
    margin-top: 5px;
  }
  .chatPageSubtitle {
    font-size: var(--wide-screen-font-size);
  }
  .suggestionButton {
    padding: 9.5px 12px 6.5px;
  }
  .chatNavButton, .messageRow, .suggestionButtons, .suggestionButton, .newMessage, .newMessage::placeholder, .chatInputRow * {
    font-size: var(--wide-screen-font-size);
  }
}

.chatContainer {
  width: calc(100% - 24px); /* Adjusted for responsiveness */
  height: calc(100vh - var(--navbar-height) - 20px - 20px - 34px);
  overflow-y: auto;
  border: 2px solid orange;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  flex-direction: column;
  --question-box-width: calc(min(80%, 100% - 115px * 2));
  --question-box-margin: calc((100% - var(--question-box-width)) / 2);
}


.chat {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.pastMessages {
  flex-grow: 1;
  padding-right: 10px;
  margin-bottom: 10px;
  height: calc(85vh - 110px);
  overflow-y: auto;
}

.scrollAnchor {
  height: 1px;
}

.textBoxWithButton textarea {
  flex-grow: 1;
}

.newMessage {
  border: none;
  border-radius: 10px;
  background-color: transparent;
  box-sizing: border-box;
  resize: none;
  outline: none;
  line-height: 1.5;
  margin-right: 30px;
  overflow-y: auto;
  max-height: 36px;
  padding: 6px 2px;
  scrollbar-width: none;
  width: var(--question-box-width);
}

.newMessage::placeholder,
.chatInputRow textarea::placeholder {
  color: #737272;
}

.textBoxWithButton {
  border-radius: 10px;
  background-color: #f9f9f9;
  padding: 10px;
  margin: 10px 0 10px 0;
  width: calc(80% - 20px);
}


.newMessageBox {
  height: 90px;
}

.newMessageBox {
  height: auto;
  background-color: white;
  align-items: center;
  justify-content: center;
  padding: 4px;
  align-self: flex-end;
}

.newMessageBox > .flexRow {
  width: 100%;
}


.expressionSubmit {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.expressionSubmitWrapper {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 50%;
  padding: 0;
}

.messageRow {
  width: 100%;
}

.fromSelf {
  justify-content: flex-end;
}

.message {
  max-width: max(70%, 100% - 50px);
  padding: 10px;
  margin: 5px;
  background-color: #f3f3f3;
  border-radius: 20px;
  white-space: pre-wrap;
}

.navigationButtons {
  display: flex;
  justify-content: space-between;
}

.chatNavButton {
  border: none;
  padding: 11.5px 15px 8.5px;
  border-radius: 20px;
  transition: background-color 0.3s ease;
}

.prevQuestion {
  margin-right: 10px;
  margin-left: calc(var(--question-box-margin) - 115px);
  width: 105px;
}

.nextQuestion {
  margin-left: 10px;
  width: 75px;
  margin-right: calc(var(--question-box-margin) - 85px);
}

.chatNavButton:hover {
  background-color: #e67e22;
}

.navigationContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.currentQuestion {
  text-align: center;
  margin: 0;
  font-size: 1.25rem;
}



.fromServer {
  justify-content: flex-start;
}

.fromSelf {
  justify-content: flex-end;
}

.suggestionButtons {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  width: var(--question-box-width);
  margin-top: 10px;
  margin-left: var(--question-box-margin);
}

.suggestionButton {
  background-color: #007bff;
  border-radius: 20px;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.suggestionButton:hover {
  transform: scale(1.02, 1.05); /* Slight scaling for interactivity */
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2); /* Add depth on hover */
}

.chatFooter {
  height: calc(var(--chat-footer-height) - 7px);
  padding-top: 7px;
  text-align: center;
}
.chatFooterText {
  color: DimGrey;
  text-align: 'center';
}


.submitGraphAnswer {
  margin-right: 10px;
  padding: 10px 15px;
  background-color: #f98b02;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.closeGraphModal {
  padding: 10px 15px;
  background-color: gray;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.graphModalOuter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 5%;
}

.graphModalInner {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
}
